html, body {
		height:100%;
	
}


header {
/*	position:relative;
		top: 50%;
	left: 50%;
		transform: translateX(-50%) translateY(-50%); 
	
	old method which uses transform (not supported by all browsers, but works fine on up to date) */
	
	font-family: arial;
	border-style: none;
	text-align: center;
	width: 70%;
	margin: auto;
	color:#4D4D4D; 
}

.container-fluid {
	display: flex;
	width: 100%;
	margin: 0 auto;
	justify-content: center;
}

h3,
small {
	font-size: small;
	color: #4B575F;
}

header img{
	margin:0;
	padding:0;

}

@media (min-width: 967px) {
	.addressBlock { text-align:left }
	 .phoneBlock { text-align:right;}
	 .webBlock { text-align:right }	
}

.red {
border:1px solid red;
}

.img-responsive { 
    width:35%;
}

.orangeBord {
	border-top: 2px solid orange;
	padding-top: 1%;
}

.horizzontal {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin: 0 auto;
	justify-content: space-around;
}

h4
{
	font-size: medium;
}